<template>
    <div>
        <!-- <p class="has-text-centered">{{ yearSelected.name }}</p> -->

        <br>

        <button class="button" :class="{ 'year-active': y.name === yearSelected.name}" v-for="y in yearOptions" :key="y.id" @click="selectYear(y)">
            {{ y.name }}
        </button>

        <hr>

        <div v-for="pw in currentPolicies" :key="pw.name">

            <p class="title is-5 my-4">{{ pw.text }}</p>

            <div class="box pw-box background-c2g-blue has-text-white my-1">
                <div class="columns">
                    <div class="column is-1">
                        <b-icon pack="fa" icon="file-pdf" size="is-large" type="is-primary"/>
                    </div>
                    <div class="column">
                        <p>Single Trip</p>
                        <hr class="sep">
                        <a :href="$c2g.getPolicyWordings('PLATINUM', false, false, pw.value, 'ST')" target="_blank">Platinum Policy Wording</a> |
                        <a :href="$c2g.getPolicyWordings('GOLD', false, false, pw.value, 'ST')" target="_blank">Gold Policy Wording</a> |
                        <a :href="$c2g.getPolicyWordings('SILVER', false, false, pw.value, 'ST')" target="_blank">Silver Policy Wording</a>
                    </div>
                </div>
            </div>

            <div class="box pw-box background-c2g-blue has-text-white my-1">
                <div class="columns">
                    <div class="column is-1">
                        <b-icon pack="fa" icon="file-pdf" size="is-large" type="is-primary"/>
                    </div>
                    <div class="column">
                        <p>Annual Multi-Trip</p>
                        <hr class="sep">
                        <a :href="$c2g.getPolicyWordings('PLATINUM', false, false, pw.value, 'AT')" target="_blank">Platinum Policy Wording</a> |
                        <a :href="$c2g.getPolicyWordings('GOLD', false, false, pw.value, 'AT')" target="_blank">Gold Policy Wording</a> |
                        <a :href="$c2g.getPolicyWordings('SILVER', false, false, pw.value, 'AT')" target="_blank">Silver Policy Wording</a>
                    </div>
                </div>
            </div>
            <div class="box pw-box background-c2g-blue has-text-white my-1">
                <div class="columns">
                    <div class="column is-1">
                        <b-icon pack="fa" icon="file-pdf" size="is-large" type="is-primary"/>
                    </div>
                    <div class="column">
                        <p>Longstay</p>
                        <hr class="sep">
                        <a :href="$c2g.getPolicyWordings('LONGSTAY', false, false, pw.value, 'LS')" target="_blank">Longstay Policy Wording</a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { contentAPI } from "@/mixins/content-api";
    import { page } from "@/mixins/page";

    export default {
        name: "PolicyWording",
        data() {
            return {
                yearOptions: [
                    { name: 'For Policies Purchased from 00:00 08/05/2024', options: [13] },
                    { name: 'Older', options: 0 }
                ],
                policyWordings: [
                    { name: '08/05/2024', text: 'For Policies Purchased from 00:00 08/05/2024', value: 13 },
                ],
                yearSelected: null
            }
        },
        computed: {
            currentPolicies() {
                return this.policyWordings.filter(pw => {
                    return this.yearSelected.options.includes(pw.value);
                });
            }
        },
        methods: {
            selectYear(y) {
                if (y.options === 0) {
                    this.$router.push({ name: 'policyWordings' })
                } else {
                    this.yearSelected = y;
                }
            }
        },
        mixins: [ contentAPI, page ],
        created() {
            this.yearSelected = this.yearOptions[0];
        }
    }
</script>

<style lang="scss" scoped>
    .pw-box {
        border-bottom: 3px $c2g_orange solid;
    }
    .sep {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .year-active {
        background-color: $c2g_orange;
        border-color: $c2g_orange;
        color: white;
    }
    button {
        margin-right: 5px;
    }
</style>